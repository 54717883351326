import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ALLOWED_OVERRIDE_FEATURE_FLAGS_TOKEN,
  ANALYTICS_CONFIG,
  ANALYTICS_SERVICE,
  AuthService,
  CONFIG_PROVIDERS,
  ConfigurationService,
  CsrfHeaderInterceptor,
  EnvironmentContextModule,
  FeatureFlagDirective,
  FeatureFlagsModule,
  GraphQLModule,
  KernelComponentsModule,
  PendoAnalyticsService,
  USE_FAKE_AUTH_TOKEN,
} from '@hxp/kernel';
import { DashboardModule } from '@hxp/nucleus';
import { HyAuthService, HyMaterialIconModule } from '@hyland/ui';
import { HyHeaderModule, HyShellModule } from '@hyland/ui-shell';
import { environment } from '../environments/environment';
import { RoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardContentWithBannerComponent } from './components/dashboard-content-with-banner/dashboard-content-with-banner.component';
import { DashboardView } from './dashboard/dashboard.view';
import { AppListComponent } from './shared/components/app-list/app-list.component';
import { ContentCannotBeViewedView } from './shared/components/content-cannot-be-viewed/content-cannot-be-viewed.view';
import { UnassignedView } from './unassigned/unassigned.view';

@NgModule({
  declarations: [AppComponent, DashboardView, UnassignedView, ContentCannotBeViewedView],
  imports: [
    DashboardContentWithBannerComponent,
    HyShellModule.forRoot(),
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    GraphQLModule,
    HttpClientModule,
    HyMaterialIconModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatToolbarModule,
    MatTooltipModule,
    RoutingModule,
    KernelComponentsModule,
    EnvironmentContextModule,
    AppListComponent,
    DashboardModule,
    HyHeaderModule,
    FeatureFlagDirective,
    FeatureFlagsModule.forRoot({ useLocalStrategy: environment.useLocalFeatureFlags }),
  ],
  providers: [
    CONFIG_PROVIDERS,
    { provide: USE_FAKE_AUTH_TOKEN, useValue: environment.useFakeAuth },
    { provide: HyAuthService, useExisting: AuthService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CsrfHeaderInterceptor,
      multi: true,
    },
    {
      provide: ANALYTICS_CONFIG,
      useFactory: (config: ConfigurationService) => {
        const c = config.getConfig();
        return c.analytics;
      },
      deps: [ConfigurationService],
    },
    { provide: ANALYTICS_SERVICE, useClass: PendoAnalyticsService },
    {
      provide: ALLOWED_OVERRIDE_FEATURE_FLAGS_TOKEN,
      useValue: ['nucleus-ce-non-provisioned-apps', 'nucleus-ce-show-override-flag-options', 'ce-backlog'],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('sharebase', sanitizer.bypassSecurityTrustResourceUrl('assets/sharebase.svg'));
    iconRegistry.addSvgIcon('hxp', sanitizer.bypassSecurityTrustResourceUrl('assets/hxp.svg'));
    iconRegistry.addSvgIcon('capture', sanitizer.bypassSecurityTrustResourceUrl('assets/capture.svg'));
    iconRegistry.addSvgIcon('pptx', sanitizer.bypassSecurityTrustResourceUrl('assets/pptx.svg'));
    iconRegistry.addSvgIcon('docx', sanitizer.bypassSecurityTrustResourceUrl('assets/docx.svg'));
    iconRegistry.addSvgIcon('default', sanitizer.bypassSecurityTrustResourceUrl('assets/default.svg'));
    iconRegistry.addSvgIcon('task', sanitizer.bypassSecurityTrustResourceUrl('assets/task.svg'));
  }
}
